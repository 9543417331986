<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Kişisel Bilgiler</b-card-title>
        <b-button
          variant="flat-success"
          :href="downloadUrl"
          target="_blank"
        >
          <FeatherIcon icon="PrinterIcon" />
          Yazdır
        </b-button>
      </b-card-header>
      <b-table-simple>
        <b-tbody>
          <b-tr>
            <b-th
              class="bg-light width-300"
            >
              TC Kimlik No
            </b-th>
            <b-td>{{ itemData.tckn }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Adı Soyadı
            </b-th>
            <b-td>{{ itemData.adi }} {{ itemData.soyadi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Doğum Tarihi
            </b-th>
            <b-td>{{ moment(itemData.dogumtarihi).format('DD.MM.YYYY') }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Doğum Yeri
            </b-th>
            <b-td>{{ itemData.dogumyeri }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Cinsiyet
            </b-th>
            <b-td>{{ itemData.cinsiyet === '1'? 'Erkek' : 'Kadın' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Seyehat Engeli
            </b-th>
            <b-td>{{ itemData.seyahat === '1'? 'Var' : 'Yok' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Cep Telefonu
            </b-th>
            <b-td>{{ itemData.ceptelefonu }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Telefon
            </b-th>
            <b-td>{{ itemData.telefon }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              E-Posta
            </b-th>
            <b-td>{{ itemData.eposta }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Baba Adı
            </b-th>
            <b-td>{{ itemData.babaadi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Anne Adı
            </b-th>
            <b-td>{{ itemData.anneadi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Medeni Durum
            </b-th>
            <b-td>{{ itemData.medenidurum === '1'? 'Evli' : 'Bekar' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Adres
            </b-th>
            <b-td>{{ itemData.adres }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Acil Durumda Aranacak Yakını
            </b-th>
            <b-td>{{ itemData.aciladi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Acil Durumda Telefon
            </b-th>
            <b-td>{{ itemData.aciltelefon }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Askerlik
            </b-th>
            <b-td>{{ itemData.askerlik === '1'? '-' : 'Yapıldı' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Adli Soruşturma
            </b-th>
            <b-td>{{ itemData.adli === '1'? 'Var' : 'Yok' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Adli Soruşturma Sebep
            </b-th>
            <b-td>{{ itemData.adlisebep }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Ehliyet
            </b-th>
            <b-td>{{ itemData.ehliyet === '1'? 'Var' : 'Yok' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th

              class="bg-light"
            >
              Ehliyet Sınıfı
            </b-th>
            <b-td>{{ itemData.ehliyetsinif }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="bg-light"
            >
              Önemli Hastalık veya Ameliyat
            </b-th>
            <b-td>{{ itemData.hastalik === '1'? 'Var' : 'Yok' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th
              class="bg-light"
            >
              Bedensel Engel
            </b-th>
            <b-td>{{ itemData.engel === '1'? 'Var' : 'Yok' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Eğitim Bilgileri</b-card-title>
      </b-card-header>
      <b-table-simple>
        <b-tbody>
          <b-tr>
            <b-th class="bg-light width-300">
              İlk Okul
            </b-th>
            <b-td>{{ itemData.ilkokuladi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              İlk Okul Bölüm
            </b-th>
            <b-td>{{ itemData.ilkokulbolum }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              İlk Okul Başlangıç
            </b-th>
            <b-td>{{ itemData.ilkokulbaslangic }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              İlk Okul Bitiş
            </b-th>
            <b-td>{{ itemData.ilkokulbitis }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Lise
            </b-th>
            <b-td>{{ itemData.liseadi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Lise Bölüm
            </b-th>
            <b-td>{{ itemData.lisebolum }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Lise Başlangıç
            </b-th>
            <b-td>{{ itemData.lisebaslangic }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Lise Bitiş
            </b-th>
            <b-td>{{ itemData.lisebitis }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Ön Lisans
            </b-th>
            <b-td>{{ itemData.onlisansadi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Ön Lisans Bölüm
            </b-th>
            <b-td>{{ itemData.onlisansbolum }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Ön Lisans Başlangıç
            </b-th>
            <b-td>{{ itemData.onlisansbaslangic }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Ön Lisans Bitiş
            </b-th>
            <b-td>{{ itemData.onlisansbitis }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Lisans
            </b-th>
            <b-td>{{ itemData.lisansadi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Lisans Bölüm
            </b-th>
            <b-td>{{ itemData.lisansbolum }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Lisans Başlangıç
            </b-th>
            <b-td>{{ itemData.lisansbaslangic }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Lisans Bitiş
            </b-th>
            <b-td>{{ itemData.lisansbitis }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Yüksek Lisans
            </b-th>
            <b-td>{{ itemData.ylisansadi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Yüksek Lisans Bölüm
            </b-th>
            <b-td>{{ itemData.ylisansbolum }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Yüksek Lisans Başlangıç
            </b-th>
            <b-td>{{ itemData.ylisansbaslangic }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light">
              Yüksek Lisans Bitiş
            </b-th>
            <b-td>{{ itemData.ylisansbitis }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Yabancı Dil</b-card-title>
      </b-card-header>
      <b-table-simple>
        <b-tbody>
          <b-tr>
            <b-th class="bg-light width-300">
              Yabancı Dil
            </b-th>
            <b-td>{{ itemData.yabancidil }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light width-300">
              Okuma
            </b-th>
            <b-td>{{ itemData.ydilokuma }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light width-300">
              Okuma
            </b-th>
            <b-td>{{ itemData.ydilyazma }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light width-300">
              Okuma
            </b-th>
            <b-td>{{ itemData.ydilkonusma }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Bilgisayar Bilgisi</b-card-title>
      </b-card-header>
      <b-table-simple>
        <b-tbody>
          <b-tr>
            <b-th class="bg-light width-300">
              MS Word
            </b-th>
            <b-td>
              <span v-if="itemData.word === '1'">Az</span>
              <span v-else-if="itemData.word === '2'">Orta</span>
              <span v-else-if="itemData.word === '3'">İyi</span>
              <span v-else>-</span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light width-300">
              MS Excel
            </b-th>
            <b-td>
              <span v-if="itemData.excel === '1'">Az</span>
              <span v-else-if="itemData.excel === '2'">Orta</span>
              <span v-else-if="itemData.excel === '3'">İyi</span>
              <span v-else>-</span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light width-300">
              MS Powerpoint
            </b-th>
            <b-td>
              <span v-if="itemData.powerpoint === '1'">Az</span>
              <span v-else-if="itemData.powerpoint === '2'">Orta</span>
              <span v-else-if="itemData.powerpoint === '3'">İyi</span>
              <span v-else>-</span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light width-300">
              Diğer
            </b-th>
            <b-td>{{ itemData.bilgisayardiger }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Eğitim ve Sertifikalar</b-card-title>
      </b-card-header>
      <b-table-simple fixed>
        <b-thead>
          <b-tr>
            <b-th>Eğitimin Konusu</b-th>
            <b-th>Eğitimi Veren Kuruluşun Adı</b-th>
            <b-th>Eğitimin Süresi</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item,key) in itemData.certificate"
            :key="key"
          >
            <b-td>{{ item.egitimkonu }}</b-td>
            <b-td>{{ item.egitimkurum }}</b-td>
            <b-td>{{ item.egitimsure }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Staj ve İş Tecrübeleri</b-card-title>
      </b-card-header>
      <b-table-simple fixed>
        <b-thead>
          <b-tr>
            <b-th>Firma Adı</b-th>
            <b-th>Telefon</b-th>
            <b-th>Giriş Tarihi</b-th>
            <b-th>Çıkış Tarihi</b-th>
            <b-th>Görev</b-th>
            <b-th>Ayrılma Nedeni</b-th>
            <b-th>Net Ücret</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item,key) in itemData.experience"
            :key="key"
          >
            <b-td>{{ item.firmaadi }}</b-td>
            <b-td>{{ item.firmatelefon }}</b-td>
            <b-td>{{ item.isgiris ? moment(item.isgiris).format('ll') : '-' }}</b-td>
            <b-td>{{ item.iscikis ? moment(item.iscikis).format('ll') : '-' }}</b-td>
            <b-td>{{ item.gorev }}</b-td>
            <b-td>{{ item.ayrilmanedeni }}</b-td>
            <b-td>{{ item.netucret }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Diğer Bilgiler</b-card-title>
      </b-card-header>
      <b-table-simple>
        <b-tbody>
          <b-tr>
            <b-th class="bg-light width-300">
              Talep Ettiğiniz Departman
            </b-th>
            <b-td>{{ itemData.tdepartman }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light width-300">
              Talep Ettiğiniz Ücret
            </b-th>
            <b-td>{{ itemData.tnetucret }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light width-300">
              İşe Alınırsanız Ne Zaman Başlarsınız
            </b-th>
            <b-td>{{ itemData.nezamanbaslar }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light width-300">
              Hobi / İlgi Alanları
            </b-th>
            <b-td>{{ itemData.hobi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light width-300">
              Üye Olduğunuz Dernek, Sendika ve Kuruluş
            </b-th>
            <b-td>{{ itemData.uyekurulus }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light width-300">
              İki Aylık Deneme Süresi
            </b-th>
            <b-td>{{ itemData.denemesuresi === '1'? 'Kabul Ediyorum' : 'Kabul Etmiyorum' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Referanslar</b-card-title>
      </b-card-header>
      <b-table-simple fixed>
        <b-thead>
          <b-tr>
            <b-th>Adı Soyadı</b-th>
            <b-th>Firma Adı</b-th>
            <b-th>Görevi</b-th>
            <b-th>Telefonu</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item,key) in itemData.reference"
            :key="key"
          >
            <b-td>{{ item.radisoyadi }}</b-td>
            <b-td>{{ item.rfirmaadi }}</b-td>
            <b-td>{{ item.rgorevi }}</b-td>
            <b-td>{{ item.rtelefon }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Ayrıca Belirmek İstediğiniz Deneyim ve Özellikleriniz</b-card-title>
      </b-card-header>
      <b-table-simple>
        <b-tbody>
          <b-tr>
            <b-td>{{ itemData.ekbilgi }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-table-simple>
        <b-tbody>
          <b-tr>
            <b-th class="bg-light width-300">
              Form Onayı
            </b-th>
            <b-td>{{ itemData.formonay === '1'? 'Evet' : 'Hayır' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="bg-light width-300">
              KVKK Onayı
            </b-th>
            <b-td>{{ itemData.kvkk === '1'? 'Evet' : 'Hayır' }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
  },
  computed: {
    downloadUrl() {
      return `${this.$store.state.app.baseURL}/exports/hr/careerform/pdf?token=${localStorage.getItem('downloadToken')}&id=${this.$route.params.id}`
    },
    itemData() {
      return this.$store.getters['hrCareerForm/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('hrCareerForm/getDataItem', this.$route.params.id)
    },
  },
}
</script>
